import React from 'react';
import './App.css'; // Optional CSS file for styling
import './styles/fonts.css';
import './index.css';
import Navbar from './components/navbar'; // Import the Navbar component
import Footer from './components/footer'; // Import the Footer component
import { BrowserRouter } from 'react-router-dom'; // Import BrowserRouter from react-router-dom

const ComingSoon = () => {
  return (
    <div style={styles.container}>
      <Navbar /> {/* Add Navbar here */}
      <main> {/* Wrap content in <main> for proper layout */}
        <h1 className="barlow-bold" style={styles.title}>Coming Soon</h1>
        <p className="barlow-light" style={styles.description}>
          We're working hard to bring you something amazing. Stay tuned!
        </p>
      </main>
      <Footer /> {/* Add Footer here */}
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start', // Align to the top
    minHeight: '100vh', // Use minHeight instead of height for better flexibility
    textAlign: 'center',
  },
  title: {
    fontSize: '3rem',
    margin: '0.5rem 0',
    color: '#fff',
  },
  description: {
    fontSize: '1.25rem',
    color: '#fff',
    marginBottom: '2rem',
  },
};

// Wrap the App with BrowserRouter to enable routing
const App = () => {
  return (
    <BrowserRouter>
      <ComingSoon />
    </BrowserRouter>
  );
};

export default App;
